import React, { useState, useEffect } from 'react';
import AutoCards from '../../components/AutoCards/AutoCards';
import axios from 'axios';

const Autos = () => {
    const [autos, setAutos] = useState([]);
    
    useEffect(() => {
        const getAutos = async () => {
            try {
                const { data } = await axios.get('./data.json');

                setAutos(data);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getAutos();
    }, []);

    let savedAutosStore;

    if (localStorage.getItem('savedAutos') === null) {
        savedAutosStore = [];
    } else {
        savedAutosStore = JSON.parse(localStorage.getItem('savedAutos'));
    }

    const [savedAutos, setSavedAutos] = useState(savedAutosStore);

    const saveAutoHandler = (id) => {
        let newSavedAutos = [...savedAutos];

        if (newSavedAutos.indexOf(id) !== -1) {
            newSavedAutos.splice(newSavedAutos.indexOf(id), 1);
        } else {
            newSavedAutos.push(id);
        }

        setSavedAutos(newSavedAutos);
        localStorage.setItem('savedAutos', JSON.stringify(newSavedAutos));
    }

    return (
        <div>
            <AutoCards 
                autos={autos} 
                savedAutos={savedAutos}
                saveAutoHandler={saveAutoHandler}
            />
        </div>
    )
}

export default Autos;