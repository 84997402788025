import React, { useState } from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Login from './containers/Login/Login';
import Header from './components/Header/Header';
import Autos from './containers/Autos/Autos';
import Footer from './components/Footer/Footer';

const App = () => {
    const userId = localStorage.getItem('userId');
    let isLoggedIn = null;

    if (userId !== null && userId !== '') {
        isLoggedIn = true;
    } else {
        isLoggedIn = false;
    }
    
    const [userData, setUserData] = useState({
        loggedIn: isLoggedIn
    });
    
    // useEffect(() => {
    //     const userId = localStorage.getItem('userId');

    //     console.log(`userId: ${userId}`);

    //     if (userId !== null && userId !== '') {
    //         console.log(`userId in local storage`);
    //         login();
    //     }
    // }, [])

    const login = () => {
        setUserData(userData => ({...userData, loggedIn: true}));
    }

    const logout = () => {
        setUserData(userData => ({...userData, loggedIn: false}));

        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('userId');
    }

    const checkAuthTimeout = (expirationTime) => {
        console.log(`[checkAuthTimeout called] Expiration time: ${expirationTime} msec`)
        
        setTimeout(() => {
            logout();
        }, expirationTime);
    }

    return (
        <Router>
            {!userData.loggedIn 
                ? <Login 
                    login={login} 
                    checkAuthTimeout={checkAuthTimeout} 
                />
                : <div className="App">
                    <Header 
                        logout={logout}
                    />
                    <Switch>
                        <Route exact path="/">
                            <Autos />
                        </Route>
                        <Route path="*">
                            <Autos />
                        </Route>
                    </Switch>
                    <Footer />
                 </div>
            }  
        </Router>
    );
}

export default App;