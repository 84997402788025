import React, { useEffect } from 'react';

const Input = (props) => {
    let inputElement = null;

    useEffect(() => {
        props.updateFormValid();
    }, [props.value]);

    switch (props.elementType) {
        case 'input':
            inputElement = (
                <input 
                    className={props.elementClasses}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                />
            )
            break;
        default:
            break;
    }

    return (
        <React.Fragment>
            {props.label &&
                <label htmlFor={props.id}>{props.label}</label>
            }
            {inputElement}
        </React.Fragment>
    )
}

export default Input;