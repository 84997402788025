import React from 'react';
import AutoCard from './AutoCard/AutoCard';

const AutoCards = (props) => {
    let autos = null;
    let isSaved;
    
    autos = props.autos.map(auto => {
        if (props.savedAutos.indexOf(auto.id) !== -1) {
            isSaved = true;
        } else {
            isSaved = false;
        }

        return <AutoCard
            key={auto.id}
            id={auto.id}
            year={auto.year}
            make={auto.make}
            model={auto.model}
            trim={auto.trim}
            price={auto.price}
            marketAverage={auto.marketAverage}
            margin={auto.margin}
            mileage={auto.mileage}
            location={auto.location}
            phone={auto.phone}
            image={auto.image}
            listingSite={auto.listingSite}
            url={auto.url}
            isSaved={isSaved}
            saveAutoHandler={props.saveAutoHandler}
        />
    });
    
    return (
        <div className="container mt-3">
            {autos}
        </div>
    )
}

export default AutoCards;