import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="container">
                <div className="mb-3">
                    VinAcquire &copy; {new Date().getFullYear()}, All Rights Reserved.
                </div>
                <div>
                    Powered By <img src="./images/vinaudit-v3.3_85.png" width="100" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Footer;
