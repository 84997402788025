import React from 'react';
import './DealIndicator.css';
// import { randomInteger } from '../../../../utils.js';

const DealIndicator = (props) => {
    // const randDeal = randomInteger(500, 5000);  
    // console.log(randDeal);

    const margin = props.margin;

    let dealBadge = null;
    let dealLabelClasses = ['mb-0', 'text-nowrap'];
    let dealBadgeLabel = null;
    let dealLabel = null

    if (margin > 3000) {

        dealBadge = (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="DealIndicator-best bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
            </svg>
        );
        dealLabelClasses.push('DealIndicator-best');
        dealBadgeLabel = 'BELOW MARKET AVG.';
        dealLabel = `$${margin} MARGIN`;
    
    } else if (margin > 1000 && margin <= 3000) {

        dealBadge = (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="DealIndicator-better bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
            </svg>
        );
        dealLabelClasses.push('DealIndicator-better');
        dealBadgeLabel = 'BELOW MARKET AVG.';
        dealLabel = `$${margin} MARGIN`;

    } else if (margin > 0 && margin <= 1000) {

        dealBadge = (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="DealIndicator-good bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
            </svg>
        );
        dealLabelClasses.push('DealIndicator-good');
        dealBadgeLabel = 'BELOW MARKET AVG.';
        dealLabel = `$${margin} MARGIN`;

    }

    dealLabelClasses = dealLabelClasses.join(' ');
    
    return (
        <div className="mb-2">
            <div className="float-left mr-2 mt-2">
                {dealBadge}
            </div>
            <div className="ml-2">
                <div className={dealLabelClasses}>
                    <strong>{dealBadgeLabel}</strong>
                </div>
                <div className="text-muted mt-n1">
                    {dealLabel}
                </div>
            </div>
        </div>
    )
}

export default DealIndicator;