import React, { useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import './Login.css';
import axios from 'axios';

const Login = ({ login }) => {
    const [authForm, setAuthForm] = useState({
        loginForm: {
            email: {
                elementType: 'input',
                elementConfig: {
                    id: 'email-input',
                    type: 'text',
                    placeholder: 'Email',
                    name: 'email'
                },
                elementClasses: 'Login-input-email form-control mb-2',
                value: '',
                valid: false,
                validation: {
                    required: true,
                    isEmail: true
                }

            },
            password: {
                elementType: 'input',
                elementConfig: {
                    id: 'password-input',
                    type: 'password',
                    placeholder: 'Password',
                    name: 'password'
                },
                elementClasses: 'Login-input-password form-control mb-2',
                value: '',
                valid: false,
                validation: {
                    required: true,
                    minLength: 6
                }
            }
        },
        loading: false,
        valid: false,
        errorMessage: ''
    });

    // check status of ALL form elements and update overall form validity in state
    const updateFormValid = () => {
        let isValid = true;

        for (let formElemKey in authForm.loginForm) {
            if (authForm.loginForm[formElemKey].valid === false && isValid) {
                isValid = false;
            }
        }

        setAuthForm(authForm => ({...authForm, valid: isValid}));
    }

    // build dynamic form input elements
    const formElementsArray = [];

    for (let key in authForm.loginForm) {
        formElementsArray.push({
            id: key,
            config: authForm.loginForm[key]
        });
    }

    let formElems = null;

    formElems = formElementsArray.map(formElement => {
        return <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            elementClasses={formElement.config.elementClasses}
            value={formElement.config.value}
            changed={(event) => inputChangedHandler(event, formElement.id)}
            updateFormValid={updateFormValid}
        />
    });

    const checkValid = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    // handle form input changes and update state immutably
    const inputChangedHandler = (event, inputId) => {
        const updatedForm = {...authForm.loginForm};
        const updatedFormElement = {...updatedForm[inputId]};

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValid(updatedFormElement.value, updatedFormElement.validation);
        updatedForm[inputId] = updatedFormElement;

        setAuthForm(authForm => ({...authForm, loginForm: updatedForm}));
    }

    // handle form submission
    const authFormHandler = (event) => {
        event.preventDefault();

        setAuthForm(authForm => ({...authForm, loading: true}));

        const authData = {returnSecureToken: true};

        for (let formElemKey in authForm.loginForm) {
            authData[formElemKey] = authForm.loginForm[formElemKey].value;
        }

        const FIREBASE_WEB_API_KEY = process.env.REACT_APP_FIREBASE_WEB_API_KEY;

        let url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_WEB_API_KEY}`;
        
        axios.post(url, authData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);

                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.localId);

                login();

                // checkAuthTimeout(response.data.expiresIn * 1000);
                // checkAuthTimeout(15000);
            })
            .catch(err => {
                // Common error codes:
                
                // EMAIL_NOT_FOUND
                // INVALID_PASSWORD
                // USER_DISABLED

                let errorMsg = null;

                switch(err.response.data.error.message) {
                    case 'EMAIL_NOT_FOUND':
                        errorMsg = 'The email you\'ve entered doesn\'t belong to an account. Please check your email and try again.';
                        break;
                    case 'INVALID_PASSWORD':
                        errorMsg = 'The password you\'ve entered is incorrect.';
                        break;
                    default:
                        errorMsg = 'An unknown error has occured.';
                        break;
                }

                if (errorMsg) {
                    setAuthForm(authForm => ({...authForm, errorMessage: errorMsg, loading: false}));
                }
            });
    }

    return (
        <div className="Login">
            <div className="h1 mb-5">
                <span className="Login-company-name-vin">
                    Vin
                </span>
                <span className="Login-company-name-acquire">
                    Acquire
                </span>
            </div>
            <div className="h3 mb-3 font-weight-normal">
                Please log in
            </div>
            <form className="Login-form" onSubmit={authFormHandler} noValidate>
                {formElems}
                {authForm.errorMessage &&
                    <div className="small alert alert-danger" role="alert">
                        {authForm.errorMessage}
                    </div>
                }
                <div className="mb-4">
                    <Button
                        classes="Login-button-submit btn btn-lg btn-primary"
                        type="submit"
                        loading={authForm.loading}
                        valid={authForm.valid}
                    >
                        Log in
                    </Button>
                </div>
                <div className="text-muted">
                    VinAcquire &copy; {new Date().getFullYear()}
                </div>
            </form>
        </div>
    )
}

export default Login;